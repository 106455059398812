<template>
    <div>
        <el-form :model="gateForm" :rules="rules" ref="gateForm" label-width="100px" size='medium'>
            <el-form-item label="名称" prop="gt_name">
                 <el-input v-model="gateForm.gt_name"></el-input>
            </el-form-item>
            <el-form-item label="通道类型" >
                <el-select v-model="gateForm.gt_type" style="width:100%;">
                    <el-option label="入口" :value='0'></el-option>
                    <el-option label="出口" :value='1'></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="相机序列号">
                <el-input v-model="gateForm.gt_cam_mac"></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default{
    data(){
        return{
            gateForm:{
                gt_name:this.sourceForm.gt_name,
                gt_type:this.sourceForm.gt_type,
                gt_cam_mac:this.sourceForm.gt_cam_mac,
            },
            rules:{
                gt_name: [{ required: true, message: '请输入通道名', trigger: 'blur' }],
            }
        }
    },
    props:{
        sourceForm:Object
    },
    created(){

    },
    methods:{
        getForm(){
            let flag = true
            this.$refs.gateForm.validate((valid) => {
                if (valid) {
                    this.sourceForm.gt_type=this.gateForm.gt_type
                    this.sourceForm.gt_name=this.gateForm.gt_name
                    this.sourceForm.gt_cam_mac=this.gateForm.gt_cam_mac
                    flag= true
                } else {
                    flag =false;
                }
            })
            return flag
        }
    }
}
</script>

<style>

</style>