<template>
    <div>
        <el-form label-width="100px" size='medium'>
            <el-form-item label="名称">
                <el-input v-model="name"></el-input>
            </el-form-item>
        </el-form>

        <baidu-map ak='GnVkZbGd47aleyQGoGScrNDTixMiT1Ui' center="上海" :zoom="10" :scroll-wheel-zoom="true" class="bm-view" style='height:400px' @click='mapClick'>
            <bm-marker :position="{lng: lng, lat: lat}"></bm-marker>
        </baidu-map>


    </div>
</template>


<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'

    export default{
        data(){
            return{
                name:this.sourceForm.lt_name,
                lng:this.sourceForm.lng,
                lat:this.sourceForm.lat
            }
        },
        components:{
            BaiduMap,
            BmMarker,
        },
        props:{
            sourceForm:Object
        },
        created(){
    
        },
        methods:{
            mapClick(tp, target){
                this.lat=tp.point.lat
                this.lng=tp.point.lng
            },
            getForm(){
                this.sourceForm.lt_name=this.name
                this.sourceForm.lng=this.lng
                this.sourceForm.lat=this.lat
                return this.sourceForm
            }
        }
    }
    </script>
    
    <style>
    
    </style>