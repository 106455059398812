<template>
    <div>
        <baidu-map ak='GnVkZbGd47aleyQGoGScrNDTixMiT1Ui' center="上海" :zoom="10" :scroll-wheel-zoom="true" class="bm-view" :style="{height:mapHeight}" @click='mapClick'>
            <bm-control>
                <el-button-group>
                    <el-button :type="isAddMarker?'success':'primary'" icon="el-icon-share" @click='AddMarker'></el-button>
                    <el-button type="primary" icon="el-icon-delete" @click='Del'></el-button>
                    <el-button type="primary" icon="el-icon-success" @click='Save'></el-button>
                    <el-button type="primary" icon="el-icon-edit" @click='Config'></el-button>
                    <el-button type="primary" icon="el-icon-upload2" @click='submit'></el-button>
                </el-button-group>
            </bm-control>

            <bm-marker v-if="LocationList.length>0" v-for="item in LocationList" :position="{lng: item.lng, lat: item.lat}">
                <bm-label :content="item.label" :position="{lng: item.lng, lat: item.lat}" :labelStyle="{color: 'white', fontSize : '10px',background:'rgba(0,0,0,0)',border:'1px solid transparent'}" :offset="{width: 0, height: 0}"/>
            </bm-marker>

            <bm-polyline v-for="(item,index) in routeDataList" :path="JSON.parse(item.rt_points)" :stroke-color="index===polyLineIndex?'red':'blue'" :stroke-opacity="0.8" :stroke-weight="5" @click='PolylineClick(index)'></bm-polyline>

            <bm-polyline :path="markerList" stroke-color="red" :stroke-opacity="0.8" :stroke-weight="5"></bm-polyline>


            <el-dialog v-if="dialogVisible" title="路线时长设置" :visible.sync="dialogVisible " width='500px' :append-to-body='true'>
                <el-form label-width="100px" size='medium'>
                    <el-form-item label="路线名称：">
                        <el-input v-model="routeName" style="width: 200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="路线时长：">
                        <div>
                            <el-input-number v-model="timeLength" :min="1" controls-position="right" ></el-input-number>
                            <span style="margin-left: 5px;">小时</span>
                        </div>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="SetConfig">确 定</el-button>
                </span>
            </el-dialog>
    


        </baidu-map>
    </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'
import BmPolyline from 'vue-baidu-map/components/overlays/Polyline.vue'
import BmControl from 'vue-baidu-map/components/controls/Control.vue'
import BmLabel from 'vue-baidu-map/components/overlays/Label.vue'
import {get,post} from '../util/util'

export default{
    data(){
        return{
            mapHeight:0,
            isAddMarker:false,
            routeDataList:[],
            markerList:[],//新增连线坐标

            polyLineIndex:-1,

            LocationList:[],//工地与消纳点的位置信息

            dialogVisible:false,
            routeName:'',
            timeLength:0,
        }
    },
    props:{
        locationId:Number
    },
    components:{
        BaiduMap,
        BmMarker,
        BmPolyline,
        BmLabel,
        BmControl
    },
    created(){
        var screenHeight = document.body.clientHeight
        this.mapHeight = (screenHeight*0.65) + 'px'
        
    },
    mounted(){
        get('location/getLocationDataList').then(res=>{
            if(res.rpStatus===10000){
                let arr= res.list.filter(item=>item.lt_type===0||item.lt_id===this.locationId)
                arr.forEach(item => {
                    let point=JSON.parse(item.lt_point)
                    let name=item.lt_name.substr(0,1)+item.lt_name.substr(item.lt_name.length-1,1)
                    this.LocationList.push({
                        lng:point.lng,
                        lat:point.lat,
                        label:name,
                        id:item.lt_id
                    })
                });
            }
        })
        this.getRouteDataList()
    },
    methods:{
        getRouteDataList(){
            get('route/getRouteDataList?id='+this.locationId).then(res=>{
                if(res.rpStatus===10000){
                    this.routeDataList=res.list
                }
            })
        },
        AddMarker(){
            this.isAddMarker=!this.isAddMarker
            var arr= this.LocationList.filter(a=>a.id===this.locationId)
            this.markerList.push({
                lng:arr[0].lng,
                lat:arr[0].lat
            })
            if(!this.isAddMarker){
                this.markerList=[]
            }
        },
        mapClick(tp, target){
            if(this.isAddMarker){
                this.markerList.push({lat:tp.point.lat,lng:tp.point.lng})
            }
        },
        PolylineClick(index){
            this.polyLineIndex=index
        },
        Del(){
            if(this.polyLineIndex>-1){
                this.routeDataList.splice(this.polyLineIndex,1)
                this.polyLineIndex=-1
            }
        },
        Save(){
            this.isAddMarker=false
            let data={
                rt_id:0,
                rt_points:JSON.stringify(this.markerList),
                rt_building_id:this.locationId,
                rt_time_len:1
            }
            this.routeDataList.push(data)
            this.markerList=[]
        },
        Config(){
            if(this.polyLineIndex>-1){
                this.dialogVisible=true
            }
            else{
                this.$message.error("请选择路线")
            }
        },
        SetConfig(){
            this.dialogVisible=false
            let form= this.routeDataList[this.polyLineIndex]
            form.rt_time_len=this.timeLength
            form.rt_name=this.routeName
            post('route/edit',this.routeDataList).then(res=>{
                if(res.rpStatus===10000){
                    this.$message.success(res.rpMsg)
                    this.polyLineIndex=-1
                }else{
                    this.$message.error(res.rpMsg)
                }
            })
        },
        submit(){
            post('route/edit',this.routeDataList).then(res=>{
                if(res.rpStatus===10000){
                    this.getRouteDataList()
                    this.$message.success(res.rpMsg)

                    this.markerList=[]
                    this.isAddMarker=false
                    this.polyLineInde=-1

                }else{
                    this.$message.error(res.rpMsg)
                }
            })
        }
    }
}


</script>

<style>
.label{
    color:black;
    width: 25px;
    height: 10px;
    background: rgba(0,0,0,0);
    overflow: hidden;
    text-align: center;
    padding: 10px;
    position: absolute;
}
</style>