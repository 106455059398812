<template>
    <div class="content">
        <div class="tool">
            <el-button type="primary" class="el-icon-plus" @click="AddClick"> 新增</el-button>
        </div>
        

        <treetable style='margin-top: 15px;' :data='FilterDataList' :columns='columns' border tree-type :is-fold='false' :selection-type='false' :show-index='false' :expand-type='false'>
            <template slot="gtType" slot-scope="scope">
                {{scope.row.lt_type===100?'入口':( scope.row.lt_type===101?'出口':'')}}
            </template>
            <template slot="handleEvent" slot-scope="scope">
                <el-link type="primary" v-if='scope.row.lt_type<3' @click="Add(scope.row)">新增通道</el-link>
                <el-link type="warning" @click="Edit(scope.row)">修改</el-link>
                <el-link type="danger" @click="Del(scope.row)">删除</el-link>

                <el-link type="primary" v-if='scope.row.lt_type===1' @click="RouteSet(scope.row)">路线规划</el-link>
            </template>
        </treetable>



         <!--消纳点-->
         <el-dialog v-if="rpVisible" :title="title" :visible.sync="rpVisible" width='80%' :append-to-body='true'>
            <Area :sourceForm='siteForm' ref='area'/>
            <span slot="footer" class="dialog-footer">
                <el-button @click="Ok">确 定</el-button>
            </span>
        </el-dialog>


        <!--通道-->
        <el-dialog v-if="dialogVisible" :title="title" :visible.sync="dialogVisible" width='30%' :append-to-body='true'>
            <Gate :sourceForm='gateForm' ref='gate'/>
            <span slot="footer" class="dialog-footer">
                <el-button @click="submit">保 存</el-button>
            </span>
        </el-dialog>


        <!--路线-->
        <el-dialog v-if="RouteMapDialogVisible" :title="title" :visible.sync="RouteMapDialogVisible" width='80%' :append-to-body='true'>
            <Route :locationId='locationId'/>
        </el-dialog>

    </div>
</template>

<script>
import ZkTable from 'vue-table-with-tree-grid'
import Gate from './Gate.vue'
import Area from './Area.vue'
import Route from './Route.vue'
import {get,post} from '../util/util'

export default{
    data(){
        return{
            columns: [
            {
                label: '名称',
                prop: 'lt_name'
            },
            {
                label: '通道类型',
                type: 'template',
                template: 'gtType'
            },
            {
                label: '操作',
                type: 'template',
                template: 'handleEvent'
            }],



            title:'',

            rpVisible:false,
            siteForm:{
                lt_id:0,
                lt_type:this.siteType,
                lt_name:'',
                lng:0,
                lat:0
            },

            dialogVisible:false,
            gateForm:{
                gt_id:0,
                gt_location_id:0,
                gt_name:'',
                gt_type:0,
                gt_cam_mac:''
            },


            RouteMapDialogVisible:false,
            locationId:0
        }
    },
    props:{
        siteType:Number
    },
    components:{
        'treetable':ZkTable,
        Area,
        Gate,
        Route
    },
    computed:{
        FilterDataList(){
            let locationData=this.$store.state.LocationDataList
            let gateData=this.$store.state.GateDataList
            var DataList=[]
            locationData.forEach(item => {
                var arr=gateData.filter(a=>a.gt_location_id===item.lt_id)
                var form={
                    lt_name:item.lt_name,
                    lt_id:item.lt_id,
                    lt_type:item.lt_type,
                    lt_point:item.lt_point,
                    children:[]
                }
                arr.forEach(a=>{
                    form.children.push({
                        lt_name:a.gt_name,
                        lt_id:a.gt_id,
                        gt_location_id:a.gt_location_id,
                        lt_type:a.gt_type===0?3:4,
                        gt_cam_mac:a.gt_cam_mac,
                    })
                })
                DataList.push(form)
            });
            return DataList
        }
    },
    created(){
        this.$store.dispatch('getLocationDataList',this.siteType)
    },
    mounted(){
        if(this.$store.state.GateDataList.length===0){
            this.$store.dispatch('getGateDataList')
        }
    },
    methods:{
        AddClick(){
            this.rpVisible=true
            this.title='新增'+(this.siteType===0?'消纳点':(this.siteType===1?'工地':'临时消纳点'))
            this.siteForm.lt_id=0
            this.siteForm.lt_name=''
            this.siteForm.lng=0
            this.siteForm.lat=0
        },
        Ok(){
            var form= this.$refs.area.getForm()
            console.log(form)
            if(form.lt_name){
                this.siteForm.lt_point=JSON.stringify({lng:form.lng,lat:form.lat})
                post('location/edit',this.siteForm).then(res=>{
                    if(res.rpStatus===10000){
                        this.rpVisible=false
                        this.$store.dispatch('getLocationDataList',this.siteType)
                    }
                })

            }else{
                this.$message.error('请输入'+(this.siteType===0?'消纳点':(this.siteType===1?'工地':'临时消纳点'))+'名称')
            }
        },

        Edit(row){
            
            let data=JSON.parse(JSON.stringify(row))
            this.title='修改'
            if(row.lt_type<3){
                this.siteForm.lt_id=data.lt_id
                this.siteForm.lt_name=data.lt_name
                var point= JSON.parse(data.lt_point)
                this.siteForm.lng=point.lng
                this.siteForm.lat=point.lat
                this.rpVisible=true
            }
            else{
                this.gateForm.gt_id=data.lt_id
                this.gateForm.gt_location_id=data.gt_location_id
                this.gateForm.gt_name=data.lt_name
                this.gateForm.gt_type=data.lt_type===3?0:1
                this.gateForm.gt_cam_mac=data.gt_cam_mac
                this.dialogVisible=true
            }
        },
        Del(row){
            this.$confirm('确定删除'+(row.lt_type===0?(this.siteType===0?'消纳点':(this.siteType===1?'工地':'临时消纳点')):'通道')+'“' + row.lt_name + '”？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if(row.lt_type<3){
                    get('location/del?id=' + row.lt_id+'&name='+row.lt_name).then(res => {
                        if (res.rpStatus === 10000) { this.$store.dispatch('getLocationDataList',this.siteType) } else { this.$message.error(res.rpMsg) }
                    })
                }
                else{
                    get('gate/del?id=' + row.lt_id+'&name='+row.lt_name).then(res => {
                        if (res.rpStatus === 10000) { this.$store.dispatch('getGateDataList') } else { this.$message.error(res.rpMsg) }
                    })
                }
            }).catch(e => e)
        },
        
        //通道
        Add(row){
            this.gateForm.gt_id=0
            this.gateForm.gt_location_id=row.lt_id
            this.gateForm.gt_name=''
            this.gateForm.gt_type=0
            this.gateForm.gt_cam_mac=''
            this.dialogVisible=true
            this.title='新增通道'
        },
        submit(){
            var flag= this.$refs.gate.getForm()
            if(flag){
                post('gate/edit',this.gateForm).then(res=>{
                    if(res.rpStatus===10000){
                        this.$store.dispatch('getGateDataList')
                        this.dialogVisible=false
                    }
                    else{
                        this.$message.error(res.rpMsg)
                    }
                })
            }
        },
    
        //路线
        RouteSet(row){
            this.RouteMapDialogVisible=true
            this.title='路线规划'
            this.locationId=row.lt_id
        }
    
    }
}
</script>

<style>
.content{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
}
.tool{
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}
</style>